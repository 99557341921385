<template>
  <button
    @click.prevent="to && handleTo()"
    :title="title"
    class="button"
  >
    <slot />
    <img v-if="icon" :src="icon"/>
  </button>
</template>

<script>
export default {
  props: ["to", "title", "icon"],
  methods: {
    handleTo() {
      if (this.$route.path !== this.to) {
        this.$router.push(this.to)
      }
    }
  }
}
</script>

<style lang="scss">
$bgWhite: rgb(255, 255, 255);
$green: rgba(0,255,0, 0.4);

.button {
  cursor: pointer;
  padding: 2.5px;
  transition: 
    background-color 0.6s ease, 
    border-color 0.6s ease,
    opacity 0.6s ease;
  border-radius: 10px;
  overflow: hidden;
  height: 45px;
  padding: 0 10px;
  position: relative;

  img {
    height: 100%;
  }

  &.roundedSquare, &.roundedRectangle {
    border: none;
    box-shadow: 5px 5px 9px -7px rgba(0, 0, 0, 0.6);
    background-color: $bgWhite;

    img {
      height: 100%;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.active {
      background-color: rgba(0,0,0,0.2)
    }
  }
  
  &.roundedSquare {
    width: 45px;
    height: 45px;
    padding: 5px;
    aspect-ratio: 1/1;
    object-fit: cover;

    img {
      width: 100%;
    }
  }

  &.roundedRectangle {
    padding: 10px;
    display: flex;
    align-items: center;
    padding-right: 55px;
    img {
      margin-left: 10px;
      position: absolute;
      background-color: $bgWhite;
      height: 45px;
      width: 45px;
      right: 0;
      padding: 10px;
    }
  }

  &.green {
    background-color: $green;
    &:hover {
      background-color: $green;
      opacity: 0.8;
    }
  }
}
</style>