import api from "../plugins/api"
import Store from "../store"
import Vue from "vue"
const $this = new Vue()

const usersApi = {
	reloadUsers: async () => {
		new Promise((resolve) => {
			api.get(`/usuarios`).then((response) => {
				$this.$set(Store.state, "users", response.data)
				resolve()
			})
		})
	},
	getLoggedUserInfo: async () => {
		if (localStorage.token) {
			api.get(`/login/info`).then((response) => {
				$this.$set(Store.state, "loggedUser", response.data)
			})
		}
	},
}

export default usersApi

Vue.use({
	install(Vue) {
		Vue.prototype.$usersApi = usersApi
	},
})
