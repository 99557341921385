<template>
	<div class="Sorting">
		<h1>Ordenar:</h1>
		<select v-model="selectSort">
			<option value="0">Padrão</option>
			<option value="1">Data de atualização</option>
			<option value="2">Prioridade</option>
		</select>
		<select v-model="selectAscDesc">
			<option value="DESC">Descendente</option>
			<option value="ASC">Ascendente</option>
		</select>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectSort: 0,
			selectAscDesc: "DESC"
		}
	},

	computed: {
		sorting() {
			let data = [
				[
					["id_status", this.selectAscDesc],
					["prioridade", this.selectAscDesc],
					["updatedAt", "ASC"]
				],
				[["updatedAt", this.selectAscDesc]],
				[["prioridade", this.selectAscDesc]]
			]

			return data
		}
	},

	watch: {
		selectSort() {
			this.$store.state.sorting = this.sorting[this.selectSort]
		},
		selectAscDesc() {
			this.$store.state.sorting = this.sorting[this.selectSort]
		}
	}
}
</script>

<style lang="scss">
.Sorting {
	display: flex;
	flex-direction: row;
	align-items: center;
  flex-grow: 1;
  height: 100%;
	h1 {
		display: inline-block;
		font-weight: lighter;
		margin-right: 15px;
		font-size: 25px;
	}

	select {
		background-color: rgba(255, 255, 255, 0);
		height: 70%;
		padding: 0 10px;
		border: none;
    border-radius: 10px;
    margin-left: 10px;
		transition: background-color 0.6s ease;
		cursor: pointer;
		&:hover {
			background-color: rgba(255, 255, 255, 1);
		}
	}
}
</style>
