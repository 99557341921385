import Vue from 'vue'
import Moment from "moment"

const formatDate = (date) => {
    return Moment(date).format("DD/MM/YYYY - HH:mm:ss")
}

Vue.use({
	install(Vue) {
		Vue.prototype.$formatDate = formatDate
        Vue.prototype.$time = Moment
	},
})