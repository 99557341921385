<template>
	<div :class="`menu ${loggedUser.id ? 'visible' : ''}`">
		<transition name="fade">
			<Button
				:to="`/window/users/${loggedUser.id}`"
				:title="text.btnUserText"
				:icon="userPicture"
				class="roundedSquare btnUser"
			/>
		</transition>
		<div class="dynamicButtons">
			<Button 
        :class="active(option.funcao)? 'active':''"
        :icon="require(`./assets/${option.icone}`)"
        :key="key" 
        :title="option.nome"
        :to="option.funcao"
        v-for="(option, key) of menuOptions"
      />
		</div>
		<div class="fixedButtons">
			<Button
				to="/window/settings"
				:title="text.btnSettingsText"
				:icon="require('./assets/settings.png')"
			/>
			<Button
				to="/login"
				:title="text.btnLogoutText"
				:icon="require('./assets/exit.png')"
			/>
		</div>
	</div>
</template>

<script>
import Button from "../Button"

export default {
	components: { Button },

	computed: {
		text() {
			return this.$texts.menu
		},
		loggedUser() {
			return this.$store.state.loggedUser
		},
		userPicture() {
			let picture = this.loggedUser.foto
			if (this.loggedUser.foto == null) {
				picture = require("../Window/components/Users/assets/client.png")
			}
			return picture
		},
		menuOptions() {
			return this.$store.state.menuOptions
		},
	},

  methods: {
    active(route) {
      return this.$route.path === route? true:false
    }
  },

  watch: {
    loggedUser(user){
      if (user.id) {
        this.$menusApi.reloadMenus()
      }
    }
  },

	mounted() {
		this.$menusApi.reloadMenus()
	},
}
</script>

<style lang="scss">
.menu {
	position: fixed;
	height: 100vh;
	left: 0;
	top: 0;
	width: 60px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateX(-100%);
	transition: transform 0.6s ease;
  // box-shadow: 5px 5px 9px -10px rgba(0, 0, 0, 0.6);

	&.visible {
		transform: translateX(0);
	}

  .button {
    border: none !important;
  }

	.btnUser {
		margin-top: 2.5px;
    padding: unset;
    img {
      object-fit: cover;
    }
	}

	.dynamicButtons {
		flex-grow: 1;
	}

	.dynamicButtons,
	.fixedButtons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;

		button {
			margin: 5px 0;
			width: 45px;
			height: 45px;
			box-shadow: 5px 5px 9px -7px rgba(0, 0, 0, 0.6);
			background-color: rgba(255, 255, 255, 0.6);
			padding: 5px;
			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
			}

      &.active {
        background-color: rgba(0,0,0,0.2)
      }
		}
	}
}
</style>
