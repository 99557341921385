import supportApi from "./supportApi"
import usersApi from "./usersApi"
import menusApi from "./menusApi"

const socketList = (socket) => {
	return {
		connect: socket.io.on("connect", () => {
			// console.log("Conectado ao Socket")
		}),

		updatedMenu: socket.io.on("updatedMenu", () => {
			menusApi.reloadMenus()
		}),

		updatedSupport: socket.io.on("updatedSupport", () => {
			supportApi.reloadSupport()
		}),

		updatedUsers: socket.io.on("updatedUsers", () => {
			usersApi.reloadUsers()
			usersApi.getLoggedUserInfo()
		}),
	}
}

export default socketList
