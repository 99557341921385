import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const getDefaultState = () => {
	return {
		loading: false,
		loggedUser: {},
		menuOptions: [],
		sorting: [
			["id_status", "DESC"],
			["prioridade", "DESC"],
			["updatedAt", "ASC"],
		],
		support: {},
		supports: [],
		users: [],
	}
}

export default new Vuex.Store({
	state: getDefaultState(),
	mutations: {
		resetState(state) {
			Object.assign(state, getDefaultState())
		},
	},
	actions: {},
})
