import Vue from "vue"
import io from "socket.io-client"
import socketList from "@/classes/socketList"

const socket = {
	openConnection: () => {
		let opt = {}

		socket.io = new io(process.env.VUE_APP_BASE_URL, opt)

		socketList(socket)
	},

	closeConnection: () => {
		socket.io.close()
	},

	io: {},
}

Vue.use({
	install(Vue) {
		Vue.prototype.$socket = socket
	},
})
