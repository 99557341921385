import Vue from "vue"

import ptBR from './langs/ptBR'

const langs = {
  ptBR
}


const defaultLang = "ptBR"

Vue.use({
  install(Vue) {
    Vue.prototype.$texts = langs[defaultLang]
  },
})
