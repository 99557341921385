<template>
	<div class="login">
		<form ref="formLogin">
			<label for="">{{ texts.loginLabel }}:</label>
			<input
				type="text"
				required
				placeholder="Login"
				ref="txtLogin"
				v-model="login"
			/>
			<label for="">{{ texts.passwordLabel }}:</label>
			<input type="password" required placeholder="Senha" v-model="senha" />

			<p class="form-message" v-if="message != ''">{{ message }}</p>
			<button @click.prevent="formSubmit($event)">
				{{ texts.buttonText }}
			</button>
		</form>
	</div>
</template>

<script>
export default {
	mounted() {
		localStorage.clear()
		this.$store.commit("resetState")
		this.$refs.txtLogin.focus()
	},
	data() {
		return {
			login: "",
			senha: "",
			message: "",
		}
	},

	computed: {
		texts() {
			return this.$texts.login
		},
	},
	methods: {
		async formSubmit(e) {
			if (this.$refs.formLogin.checkValidity()) {
				e.preventDefault()
				const { login, senha } = this
				await this.$api
					.post("/login", { login, senha })
					.then(async (response) => {
						localStorage.setItem("token", response.data.token)
						await this.$usersApi.getLoggedUserInfo()
						this.$router.push("/")
					})
					.catch((exception) => {
						this.message = exception.response.data.message
					})
			}
		},
	},
}
</script>

<style lang="scss">
.login {
	box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
	background-color: rgba(255, 255, 255, 0.8);
	width: 70%;
	height: 60%;
	max-width: 400px;
	max-height: 300px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	form {
		width: 100%;
		label {
			display: block;
			text-align: center;
			font-size: 25px;
		}

		input[type="text"],
		input[type="password"] {
			width: 100%;
			font-size: 20px;
			margin: 10px 0;
		}

		button {
			width: 100%;
			height: 50px;
			margin-top: 20px;
			font-size: 25px;
			font-weight: 300;
			cursor: pointer;
		}

		.form-message {
			text-align: center;
			font-weight: bolder;
			font-size: 18px;
			color: rgb(200, 10, 10);
		}
	}
}
</style>
