export default {
  login: {
    loginLabel: "Login",
    passwordLabel: "Senha",
    buttonText: "Entrar"
  },
  menu: {
    btnUserText: "Editar Usuário",
    btnSettingsText: "Configurações",
    btnLogoutText: "Sair do Usuário"
  },
  supports: {
    addSupport: "Adicionar Suporte",
    support: {
      increasePriorityText: "Aumentar Prioridade",
      decreasePriorityText: "Diminuir Prioridade",
      deleteSupportText: "Apagar Suporte",
      setAsHandlingText: 'Marcar como "Em Atendimento"',
      setAsFinishedText: 'Marcar como "Atendido"'
    }
  }
}