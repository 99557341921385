import Vue from "vue"
import store from "../store"

import menusApi from "./menusApi"
import usersApi from "./usersApi"
import supportApi from "./supportApi"

const reloadData = (param = "all") => {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (finish) => {
		const apiRequests = {
			menus: menusApi.reloadMenus,
			users: usersApi.reloadUsers,
			support: supportApi.reloadSupport,
		}

		let attrs = Object.keys(apiRequests)
		if (localStorage.token) {
			if (param == "all") {
				for (let i = 0; i < attrs.length; ) {
					await apiRequests[attrs[i]]().then(() => {
						store.state.loadMessage = ""
						i++
					})
				}
			} else {
				await apiRequests[param]()
			}
		}
		finish()
	})
}

export default reloadData

Vue.use({
	install(Vue) {
		Vue.prototype.$reloadData = reloadData
	},
})
