<template>
	<div class="loading">
		<Cover index="999" :visible="loading">
			<img src="@/assets/loading.gif" class="loadingImage" />
		</Cover>
	</div>
</template>

<script>
import Cover from "./Cover"

export default {
	components: {
		Cover,
	},

	props: ["content"],

	computed: {
		loading() {
			return this.$store.state.loading
		},
	},
}
</script>

<style></style>
