import Vue from "vue"
import Router from "vue-router"
import Login from "@/views/Login/Login"
import Supports from "@/views/Supports/Supports"

Vue.use(Router)

export default new Router({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
	routes: [
		{ path: "/login", component: Login },
		{
			path: "/",
			component: Supports,
			children: [
				{
					path: "/window/:windowRoute",
				},
				{
					path: "/window/:windowRoute/:windowParam",
				},
			],
		},
	],
})
