<template>
	<div class="clients">
    <div class="header">
      <h1>Cadastro de Clientes (Consulta)</h1>
      <input type="text" v-model="razaoCliente" placeholder="Razão Social" />
    </div>
    <div class="body">

      <table>
        <thead>
          <th>Razão</th>
          <th>Filial</th>
          <th>Bairro</th>
          <th>Cidade</th>
          <th>Telefone</th>
          <th>Fax</th>
        </thead>
        <tbody>
          <tr v-for="(cliente, key) in Clientes" :key="key">
            <td>
              {{ cliente.razao }}
            </td>
            <td>
              {{
                cliente.cgccpf.substring(12, 8) != "0001"
                  ? `FILIAL ${cliente.cgccpf.substring(12, 10)}`
                  : ` MATRIZ`
              }}
            </td>
            <td style="text-align: center">{{ cliente.bairro }}</td>
            <td>{{ cliente.cidade }}</td>
            <td>{{ cliente.telefone }}</td>
            <td>{{ cliente.fax }}</td>
          </tr>
        </tbody>
      </table>
    </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			razaoCliente: "",
			Clientes: []
		}
	},

	created() {
		this.getClientes()
	},

	methods: {
		getClientes() {
			this.$api
				.get(`/clientes/razao/${encodeURIComponent(this.razaoCliente + "%")}`)
				.then(response => {
					this.Clientes = response.data
				})
		}
	},

	watch: {
		razaoCliente() {
			this.getClientes()
		}
	}
}
</script>

<style lang="scss">
.clients {
	height: 90vh;
  width: 90vw;
  max-height: 600px;
  max-width: 800px;
  padding: 15px;

  .header {
    height: 100px;
    h1 {
      font-weight: lighter;
      font-size: 20px;
    }
  
    input[type="text"] {
      width: 100%;
      display: block;
      margin: 15px auto;
      height: 30px;
      padding: 2px;
      font-size: 20px;
    }
  }

  .body {
    height: calc(100% - 100px);
    overflow: auto;

    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        th {
          padding: 5px;
          font-size: 20px;
          font-weight: lighter;
        }
      }
      tbody {
        tr {
          cursor: pointer;
          background-color: #ccc;
          td {
            padding: 5px;
          }

          &:nth-child(even) {
            background-color: white;
          }

          &:hover {
            background-color: rgb(153, 153, 153);
            color: white;
          }
        }
      }
    }
  }
}
</style>
