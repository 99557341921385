<template>
	<div class="support" :style="{ backgroundColor: support.status.cor }">
		<div class="upperPart">
			<div v-if="support.id_status !== 3" class="priority">
				<button
					@click.prevent="increasePriority()"
					:title="text.increasePriorityText"
				>
					<img src="../assets/up.svg" alt="" />
				</button>
				<p>{{ support.prioridade }}</p>
				<button
					@click.prevent="decreasePriority()"
					:title="text.decreasePriorityText"
				>
					<img src="../assets/down.svg" alt="" />
				</button>
			</div>
			<div class="data" @click="editSupport()">
				<p class="clientName">
					{{ support.cliente.razao }}
				</p>
				<p class="requesterName">
					{{ support.solicitante.nome }}
				</p>
				<div class="phone">
					<img src="../assets/contact.svg" alt="" />
					<p>
						{{ support.cliente.telefone }}
					</p>
				</div>
				<p class="module">
					{{ support.modulo.descricao }}
				</p>
                <p class="dateTime">
                    Criado em: {{$formatDate(support.createdAt)}}
                </p>
				<p class="dateTime">
					Atualizado em: {{ $formatDate(support.updatedAt) }}
				</p>
			</div>
		</div>
		<div v-if="support.id_status !== 3" class="lowerPart">
			<div class="left">
				<img src="../assets/support.svg" />
				<p>{{ support.id }}</p>
			</div>
			<div class="right">
				<button
					@click.prevent="deleteSupport()"
					:title="text.deleteSupportText"
				>
					<img src="../assets/bin.svg" alt="" />
				</button>
				<button
					@click.prevent="setAsHandling()"
					:title="text.setAsHandlingText"
				>
					<img src="../assets/process.svg" alt="" />
				</button>
				<button
					@click.prevent="setAsFinished()"
					:title="text.setAsFinishedText"
				>
					<img src="../assets/tick.svg" alt="" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["support"],

	computed: {
		sorting() {
			return this.$store.state.sorting
		},
		text() {
			return this.$texts.supports.support
		},
	},

	methods: {
		editSupport() {
      if (this.support.id_status !== 3){
        this.$router.push(`/window/support/${this.support.id}`)
      }
		},

		async increasePriority() {
			let prioridade = this.support.prioridade + 1
			let data = {
				prioridade,
			}
			await this.$api.put(`/suportes/${this.support.id}`, data)
		},

		async decreasePriority() {
			if (this.support.prioridade > 0) {
				let prioridade = this.support.prioridade - 1
				let data = {
					prioridade,
				}
				await this.$api.put(`/suportes/${this.support.id}`, data)
			}
		},

		async deleteSupport() {
			this.$vex.dialog.buttons.YES.text = "OK"
			this.$vex.dialog.buttons.NO.text = "Cancelar"
			this.$vex.dialog.confirm({
				message: "Deseja mesmo apagar este suporte?",

				callback: async (response) => {
					if (response) {
						await this.$api.delete(`/suportes/${this.support.id}`)
					}
				},
			})
		},

		async setAsFinished() {
			this.$vex.dialog.buttons.YES.text = "OK"
			this.$vex.dialog.buttons.NO.text = "Cancelar"
			this.$vex.dialog.confirm({
				message: "Deseja marcar como finalizada?",

				callback: async (response) => {
					if (response) {
						let data = {
							id_status: 3,
						}
						await this.$api.put(`/suportes/${this.support.id}`, data)
					}
				},
			})
		},

		async setAsHandling() {
			let data = {
				id_status: 1,
			}

			if (this.support.id_status == 1) {
				data.id_status = 2
			}

			await this.$api.put(`/suportes/${this.support.id}`, data)
		},
	},

	watch: {
		sorting() {
			this.$supportApi.reloadSupport()
		},
	},
}
</script>

<style lang="scss">
.support {
	width: 300px;
	height: 190px;
	margin: 5px;
	box-shadow: 6px 4px 9px -2px rgba(0, 0, 0, 0.75);
	padding: 0 5px;
	border-radius: 10px;

	.lowerPart {
		height: 40px;
		width: 100%;

		.left,
		.right {
			height: 100%;
			button {
				height: 100%;
				border: none;
				padding: 5px;
				background-color: inherit;
				margin: 0 2.5px;
				cursor: pointer;
				img {
					height: 100%;
				}

				transition: background-color 0.6s ease;
				&:hover {
					background-color: rgba(0, 0, 0, 0.05);
				}
			}
		}

		.left {
			float: left;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 8px 0;
			img {
				height: 30px;
				margin-right: 10px;
			}
			p {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 25px;
				height: 100%;
			}
		}

		.right {
			float: right;
		}
	}

	.upperPart {
		display: flex;
		width: 100%;
		height: 150px;
		.priority {
			display: inline-flex;
			width: 30px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			height: 150px;
			p {
				width: 100%;
				text-align: center;
			}
			button {
				border: none;
				background-color: inherit;
				cursor: pointer;
				padding: 2px;
				transition: background-color 0.6s ease;
				&:hover {
					background-color: rgba(0, 0, 0, 0.05);
				}
				img {
					width: 100%;
				}
			}
		}

		.data {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 10px;
			height: 150px;
			width: calc(100% - 30px);
			cursor: pointer;

			.clientName {
				font-size: 18px;
				height: 45px;
				width: 100%;
				overflow: hidden;
                text-overflow: ellipsis;
				text-align: center;
				font-weight: lighter;
                white-space: wrap;
			}

			.requesterName {
				font-size: 13px;
			}

			.phone {
				margin: 5px 0;
				display: flex;
				flex-direction: row;
				img {
					width: 20px;
					display: inline-block;
				}
				p {
					margin-left: 5px;
					width: calc(100% - 25px);
					display: inline-block;
					font-size: 18px;
				}
			}

			.module {
				font-weight: bold;
				margin-bottom: 5px;
			}

			.dateTime {
				font-size: 12px;
				font-weight: lighter;
			}
		}
	}
}
</style>
