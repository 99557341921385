<template>
	<div class="supports">
    <div class="header">
      <Sorting />
      <Button
        to="/window/support"
        title="Criar novo Suporte"
        :icon="require('./assets/add.svg')"
        class="roundedRectangle green"
      >
      Novo Suporte
      </Button>
    </div>
		<div class="supportList">
			<transition-group class="active" name="fade" tag="template">
				<Support
					v-for="support in supports.pending"
					:support="support"
					:key="support.id"
				/>
			</transition-group>
			<template v-if="supports.solved">
				<hr />
				<h1>Resolvidos</h1>
				<transition-group class="solved" name="fade" tag="div">
					<Support
						v-for="support in supports.solved"
						:key="support.id"
						:support="support"
					/>
				</transition-group>
			</template>
		</div>
	</div>
</template>

<script>
import Button from "@/components/Button"
import Sorting from "./components/Sorting.vue"
import Support from "./components/Support"

export default {
	components: { Button, Sorting, Support },

	created() {
		this.$supportApi.reloadSupport()
	},

	computed: {
		supports() {
			return this.$store.state.supports
		},
	},
}
</script>

<style lang="scss">
.supports {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 1;
	padding: 0px 0px 15px;

  .header {
    position: absolute;
    height: 70px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    box-shadow: 5px 5px 9px -7px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    z-index: 2;
  }

	.supportList {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;

    padding-top: 80px;

		h1 {
			font-weight: lighter;
			text-align: center;
			margin-bottom: 20px;
		}

		hr {
			margin: 0 auto;
			width: 90%;
		}

		.active,
		.solved {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			padding-bottom: 40px;
		}

		.solved {
			.support {
				height: 150px;
				.upperPart {
					height: 100%;
					.data {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
