import api from "../plugins/api"
import Store from "../store"
import Vue from "vue"
const $this = new Vue()

const supportApi = {
	reloadSupport: async () => {
		let data = {
			order: Store.state.sorting,
		}

		await api.post("/suportes/sort", data).then((response) => {
			$this.$set(Store.state.supports, "pending", response.data)
		})

		await api.get("/suportes/paginate/12/0").then((response) => {
			$this.$set(Store.state.supports, "solved", response.data)
		})
	},
}

export default supportApi

Vue.use({
	install(Vue) {
		Vue.prototype.$supportApi = supportApi
	},
})
