<template>
	<div class="supportForm" @click="parentClick($event)">
    <div class="header">
      <label>Protocolo:</label>
      <p>
        {{ Suporte.id == 0 ? "Ainda não definido" : Suporte.id }}
      </p>
      <label>Data da criação:</label>
      <p>{{ $formatDate(Suporte.createdAt) }}</p>
    </div>
    <div class="body">
      <div class="formDiv">
        <label for="suporte-form-cliente">Cliente:</label>
        <input
          type="text"
          id="suporte-form-cliente"
          v-model="Suporte.cliente.razao"
          :disabled="!txtCliente"
        />
        <div
          class="hoverSelect"
          :style="{ display: mostraSelCliente ? 'inherit' : 'none' }"
        >
          <table>
            <tr
              v-for="(cliente, index) in clientes"
              :key="index"
              @click="selecionaCliente(index)"
            >
              <td>{{ 
                cliente.cgccpf.substring(12, 8) != "0001"
              ? `${cliente.razao} - FILIAL ${cliente.cgccpf.substring(12, 10)}`
              : `${cliente.razao} - MATRIZ`
                }}</td>
              <td>{{ cliente.bairro }}</td>
              <td>{{ cliente.cidade }}</td>
            </tr>
          </table>
        </div>
        <button @click.prevent="alteraCliente" :disabled="!btnAlteraCliente">
          Alterar
        </button>
        <label for="solicitante">Solicitante</label>
        <input
          type="text"
          class="suporte-form-solicitante"
          v-model="Suporte.solicitante.nome"
          :disabled="!txtSolicitante"
          @focus="mostraSelSolicitante = true"
        />
        <div
          class="hoverSelect"
          :style="{ display: mostraSelSolicitante ? 'inherit' : 'none' }"
          v-if="solicitantes.length > 0"
        >
          <table>
            <tr
              v-for="(solicitante, index) in solicitantes"
              :key="index"
              @click="selecionaSolicitante(index)"
            >
              <td class="suporte-column-sel">
                {{ solicitante.nome }}
              </td>
            </tr>
          </table>
        </div>
        <button
          @click.prevent="alteraSolicitante"
          :disabled="!btnAlteraSolicitante"
        >
          Alterar
        </button>
        <label for="suporte-form-categoria">Categoria</label>
        <select
          id="suporte-form-categoria"
          v-model="Suporte.id_categoria"
          :disabled="Suporte.id_status == 3 ? true : false"
        >
          <option
            v-for="(categoria, index) in categorias"
            :key="index"
            :value="categoria.id"
          >
            {{ categoria.descricao }}
          </option>
        </select>
      </div>
      <div class="formDiv">
        <label for="suporte-form-modulo">Módulo</label>
        <select
          id="suporte-form-modulo"
          v-model="Suporte.id_modulo"
          :disabled="Suporte.id_status == 3 ? true : false"
        >
          <option
            v-for="(modulo, index) in modulos"
            :key="index"
            :value="modulo.id"
          >
            {{ modulo.descricao }}
          </option>
        </select>
        <label for="suporte-form-descricao">Descrição do problema:</label>
        <textarea
          id="suporte-form-descricao"
          cols="30"
          rows="10"
          v-model="Suporte.descricao"
          :disabled="Suporte.id_status == 3 ? true : false"
        ></textarea>
      </div>
    </div>
    <Button 
      class="roundedRectangle confirmButton" 
      @click.native.prevent="submitForm()" 
      :disabled="!formCompleto"
    >
      Confirmar
    </Button>
	</div>
</template>

<script>
import Button from '@/components/Button';

export default {
  props: ["param"],

  components:{
    Button
  },
	data() {
		return {
			Suporte: {
				id: 0,
				id_cliente: 0,
				id_solicitante: 0,
				id_modulo: 1,
				id_categoria: 1,
				id_status: 1,
				cliente: { razao: "" },
				solicitante: { nome: "" },
				descricao: ""
			},

			//Categorias e Módulos
			categorias: {},
			modulos: {},

			//Cliente
			clientes: {},
			txtClienteEnable: false,
			mostraSelCliente: false,

			//Solicitante
			solicitantes: {},
			txtSelSolicitante: true,
			mostraSelSolicitante: false
		}
	},

	mounted() {
		this.getFormData()
    if (this.supports && this.param) {
      this.Suporte = this.supports.find((val) => val.id == this.param)
    }
	},

	methods: {
		async getFormData() {
			await this.$api.get("/categorias").then(response => {
				this.categorias = response.data
			})

			await this.$api.get("/modulos").then(response => {
				this.modulos = response.data
			})
		},

		async getClientes() {
			await this.$api
				.get(
					`/clientes/razao/${encodeURIComponent(
						this.Suporte.cliente.razao + "%"
					)}`
				)
				.then(response => {
					this.clientes = response.data
				})
		},

		async getSolicitantes() {
			await this.$api
				.get(`/solicitantes/cliente/${this.Suporte.id_cliente}/`)
				.then(response => {
					this.solicitantes = response.data
				})
		},

		async gravaSolicitante() {
			let data = {
				nome: this.Suporte.solicitante.nome
			}

			let response = await this.$api.post(
				`/solicitantes/cliente/${this.Suporte.id_cliente}`,
				data
			)
			return response.data
		},

		async gravaSuporte() {
			this.Suporte.id_usuario = this.$store.state.loggedUser.id
			let response = await this.$api.post("/suportes/", this.Suporte)

			return response.data
		},

		async atualizaSuporte() {
			let { id_categoria, id_modulo, descricao, id_solicitante } = this.Suporte

			let data = {
				id_categoria,
				id_modulo,
				descricao,
        id_solicitante
			}
			let response = await this.$api.put(`/suportes/${this.Suporte.id}`, data)

			this.$store.state.support = {}

			return response
		},

    async submitForm() {
      
      if (this.Suporte.id_solicitante == 0) {
        this.Suporte.solicitante = await this.gravaSolicitante()
        this.Suporte.id_solicitante = this.Suporte.solicitante.id
      }

			this.Suporte.id != 0? await this.atualizaSuporte() : await this.gravaSuporte()
			this.$router.push("/")
		},

		selecionaCliente(id) {
			this.Suporte.id_cliente = this.clientes[id].id
			this.Suporte.cliente = this.clientes[id]
			this.getSolicitantes()
			this.mostraSelCliente = false
		},

		alteraCliente() {
			this.Suporte.id_cliente = 0
			this.Suporte.cliente = { razao: "" }
			this.solicitantes = {}
		},

		selecionaSolicitante(id) {
			this.Suporte.id_solicitante = this.solicitantes[id].id
			this.Suporte.solicitante = this.solicitantes[id]
			this.mostraSelSolicitante = false
		},

		alteraSolicitante() {
			this.getSolicitantes()
			this.Suporte.id_solicitante = 0
			this.Suporte.solicitante = { nome: "" }
		},

		parentClick(e) {
			if (
				this.mostraSelSolicitante &&
				e.target.className != "suporte-column-sel" &&
				e.target.className != "suporte-form-solicitante"
			) {
				this.mostraSelSolicitante = false
			}
		}
	},

	computed: {
		formCompleto() {
			let response = false

			if (
				this.Suporte.cliente.razao != undefined &&
				this.Suporte.descricao.length > 0 &&
				this.Suporte.solicitante.nome != "" &&
				this.Suporte.id_status != 3
			) {
				response = true
			}
			return response
		},

		btnAlteraCliente() {
			let result = true

			if (this.Suporte.id_cliente == 0 || this.Suporte.id != 0) {
				return false
			}

			return result
		},

		btnAlteraSolicitante() {
			let result = true
			if (this.Suporte.id_status == 3) {
				result = false
			}
			return result
		},

		id_cliente() {
			return this.Suporte.id_cliente
		},

		txtCliente() {
			let result = false
			if (this.id_cliente == 0) {
				result = true
			}
			return result
		},

		txtSolicitante() {
			let result = false
			if (!this.txtCliente && this.Suporte.id_solicitante == 0) {
				result = true
			}
			return result
		},

		selCliente() {
			return this.Suporte.cliente.razao
		},

    supports() {
      return this.$store.state.supports.pending
    }
	},

	watch: {
		selCliente() {
			if (this.Suporte.id_cliente == 0 && this.Suporte.cliente.razao != "") {
				this.mostraSelCliente = true
				this.getClientes()
			} else {
				this.mostraSelCliente = false
			}
		},

    supports(val) {
      if (this.param) {
        this.Suporte = val.find((val) => val.id == this.param)
      }
    }
	}
}
</script>

<style lang="scss" scooped>
.supportForm {
	width: 90vw;
    height: 90vh;
	max-height: 450px;
    max-width: 1000px;
	padding: 10px;

	.header {
		display: flex;
		align-items: center;
		p {
			margin: 0 10px;
		}
	}

  .body {
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .formDiv{
      width: 50%;
      position: relative;
      padding-bottom: 40px;
      label {
        display: block;
        width: 90%;
        margin: 10px auto;
      }
      input,
      textarea,
      select {
        display: block;
        width: 90%;
        min-height: 30px;
        margin: 5px auto 10px auto;
        padding: 5px;
      }
      button {
        width: 90%;
        display: block;
        margin: 0 auto;
      }
    }
    margin-bottom: 10px;
  }
  .confirmButton{
    display: block;
    margin: 0 auto 40px auto;
    width: 45%;
    padding: 5px;
    font-size: 35px;
    font-weight: lighter;
  }


	.hoverSelect {
		position: absolute;
		width: 90%;
		margin: -10px auto 0 auto;
		left: 0;
		right: 0;
		max-height: 150px;
		background-color: rgba(255, 255, 255, 0.9);
		border-right: 1px solid #ccc;
		border-bottom: 4px solid #ccc;
		border-left: 1px solid #ccc;
		z-index: 8;
		overflow: auto;
		table {
			border-collapse: collapse;
			width: 100%;
			tr {
				cursor: pointer;
				border-bottom: 1px solid #ccc;
				height: 40px;

				&:hover {
					background-color: #ccc;
				}

				td {
					padding: 0 5px;
				}
			}
		}
	}
}
</style>
