<template>
	<div class="users">
		<div class="header">
			<h1>Cadastro de Usuários</h1>
			<div class="headerButtons">
				<Button @click.native="$router.push('/window/users/new')">
					Novo Usuário
				</Button>
			</div>
		</div>
		<div class="list">
			<table>
				<thead>
					<th>ID</th>
					<th>Codigo</th>
					<th>Login</th>
					<th>Nome</th>
					<th>Foto</th>
				</thead>
				<tbody>
					<tr
						v-for="user in Users"
						:key="user.id"
						@click="$router.push(`/window/users/${user.id}`)"
					>
						<td>{{ user.id }}</td>
						<td>{{ user.codigo }}</td>
						<td>{{ user.login }}</td>
						<td>{{ user.nome }}</td>
						<td>
							<img
								:src="
									user.foto == null
										? require('../assets/client.png')
										: user.foto
								"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import Button from "@/components/Button"

export default {
	components: {
		Button,
	},

	props: ["param"],

	computed: {
		Users() {
			return this.$store.state.users
		},
	},
}
</script>

<style lang="scss">
.users {
	height: 90vh;
  width: 90vw;
  max-height: 600px;
  max-width: 800px;
	.header {
		padding: 15px;
		height: 110px;

		h1 {
			font-weight: lighter;
			font-size: 20px;
			margin-bottom: 15px;
		}

		.headerButtons {
			button {
				border: none;
				cursor: pointer;
				background-color: #ccc;

				&:hover {
					background-color: rgb(216, 216, 216);
				}
			}
		}
	}

	.list {
		width: 100%;
		height: calc(100% - 110px);
		overflow: auto;

		table {
			width: 100%;
			border-collapse: collapse;
			background-color: rgba(0, 0, 0, 0.3);

			thead {
				th {
					padding: 5px;
					font-size: 20px;
					font-weight: lighter;
				}
			}
			tbody {
				tr {
					cursor: pointer;
					background-color: #ccc;

					td {
						height: 50px;
						text-align: center;

						img {
							height: 100%;
							width: auto;
							aspect-ratio: 1/1;
							object-fit: cover;
							border-radius: 10px;
						}
					}

					&:nth-child(even) {
						background-color: white;
					}

					&:hover {
						background-color: rgb(153, 153, 153);
						color: white;
					}
				}
			}
		}
	}
}
</style>
