<template>
	<div :class="`cover ${visible ? 'visible' : ''}`" :style="{ zIndex: index }">
		<slot />
	</div>
</template>

<script>
export default {
	props: ["visible", "index"],
}
</script>

<style lang="scss">
.cover {
	display: flex;
	visibility: hidden;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
	transition: opacity 0.6s ease;

	&.visible {
		opacity: 1;
		visibility: inherit;
	}
}
</style>
