<template>
	<div class="window">
		<Cover index="2" :visible="true" />
		<div class="windowContent" v-if="validComponent()">
			<CloseButton />
			<div :is="window" :param="param"></div>
		</div>
	</div>
</template>

<script>
import Cover from "../Cover"
import CloseButton from "../Button/CloseButton"

import clients from "./components/Clients"
import settings from "./components/Settings"
import reports from "./components/Reports"
import support from "./components/Support"
import users from "./components/Users"

export default {
	name: "windowPopup",

	props: ["window", "param"],

	components: {
		CloseButton,
		Cover,
		clients,
		settings,
		reports,
		support,
		users,
	},

	created() {
		this.$store.state.visibleCover = true
	},

	methods: {
		validComponent() {
			let valid = false

			if (this.$options.components[`${this.window}`]) {
				valid = true
			} else {
				this.$router.push("/")
			}
			return valid
		},
	},
}
</script>

<style lang="scss">
.window {
	width: 100%;
	height: 100vh;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;

	.windowContent {
		z-index: 3;
		background-color: white;
		position: relative;
		border-radius: 10px;
	}
}
</style>
