<template>
	<div class="home">
    <div class="bg">
      <img src="@/assets/logo.svg" alt="">
    </div>
		<Menu />
		<div :class="`content ${logged ? 'logged' : ''}`">
			<slot />
		</div>
	</div>
</template>

<script>
import Menu from "@/components/Menu/Menu"

export default {
	components: {
		Menu,
	},

	computed: {
		logged() {
			return this.$store.state.loggedUser.id ? true : false
		},
	},

	mounted() {
		this.$socket.openConnection()
		this.$usersApi.getLoggedUserInfo()
	},
}
</script>

<style lang="scss">
.home {
	background-color: rgb(226, 224, 224);
	height: 100%;
	width: 100%;

  .bg {
    position: absolute;
		height: 100vh;
		width: 100vw;
		opacity: 0.1;
    img {
      height: 100%;
      width: 100%;
    }
  }

	.content {
		height: 100%;
		width: 100%;
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		transition: left 0.6s ease;

		&.logged {
			width: calc(100% - 60px);
			left: 60px;
		}
	}
}
</style>
