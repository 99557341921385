<template>
  <Button class="closeButton" to="/">X</Button>
</template>

<script>
import Button from './index'
export default {
  components: {
    Button
  }
}
</script>

<style lang="scss">
$closeButtonColor: rgb(254, 108, 91);
$closeButtonDefaultBorder: 1px solid rgba(0,0,0,0.1);

.closeButton {
  position: absolute;
  right: 0;
  z-index: 7;
  width: 40px;
  height: 30px;
  border: none;
  border-radius: unset;
  border-bottom-left-radius: 10px;
  border-bottom: $closeButtonDefaultBorder;
  border-left: $closeButtonDefaultBorder;
  &:hover {
    background-color: $closeButtonColor;
    border-bottom: 1px solid $closeButtonColor;
    border-left: 1px solid $closeButtonColor;
  }
}
</style>