<template>
	<div class="registration">
		<div class="content">
			<div class="fields">
				<label>Nome</label>
				<input
					type="text"
					placeholder="Nome do Usuário"
					v-model="Usuario.nome"
				/>
				<label>Login</label>
				<input
					type="text"
					placeholder="Login do Usuário"
					v-model="Usuario.login"
				/>
				<label>Código</label>
				<input
					type="number"
					placeholder="Código do Usuário no SAPE"
					v-model="Usuario.codigo"
				/>
				<label>Senha</label>
				<input
					type="password"
					placeholder="Senha do Usuário"
					v-model="Usuario.senha"
				/>
				<label>Confirme a Senha</label>
				<input
					type="password"
					placeholder="Confirmação da Senha"
					v-model="confirmSenha"
				/>
			</div>
			<div class="picture">
				<div class="foto">
					<img :src="urlFoto" alt="" />
				</div>
				<input type="file" @change="setFoto($event)" />
			</div>
		</div>
		<button
			class="confirmButton"
			:disabled="!validForm"
			@click.prevent="submitForm()"
		>
			Confirmar
		</button>
	</div>
</template>

<script>
export default {
	props: ["userId"],
	data() {
		return {
			Usuario: {
				nome: "",
				login: "",
				senha: "",
				codigo: "",
			},
			confirmSenha: "",
			foto: null,
			urlFoto: "",
		}
	},

	created() {
		if (this.userData) {
			this.Usuario = JSON.parse(JSON.stringify(this.userData))
			this.urlFoto =
				this.Usuario.foto == null
					? require("../assets/client.png")
					: this.Usuario.foto
			this.Usuario.senha = ""
		}
	},

	methods: {
		setFoto(foto) {
			this.urlFoto = URL.createObjectURL(foto.target.files[0])
			this.Usuario.foto = foto.target.files[0]
		},

		async submitForm() {
			let data = new FormData()
			data.append("nome", this.Usuario.nome)
			data.append("login", this.Usuario.login)
			this.Usuario.senha != "" ? data.append("senha", this.Usuario.senha) : ""
			data.append("codigo", this.Usuario.codigo)
			data.append("foto", this.Usuario.foto)

			if (this.Usuario.id != undefined) {
				await this.updateUser(data)
			} else {
				await this.createUser(data)
			}

			this.$router.push("/window/users")
		},

		async createUser(data) {
			let response = await this.$api.post("/usuarios", data)
			return response
		},

		async updateUser(data) {
			let response = await this.$api.put(`/usuarios/${this.Usuario.id}`, data)
			return response
		},
	},

	computed: {
		validForm() {
			let response = false

			if (this.Usuario.nome != "" && this.Usuario.login != "") {
				response = true

				if (this.Usuario.senha != "" || this.Usuario.senha != undefined) {
					if (this.Usuario.senha != this.confirmSenha) {
						response = false
					}
				}
			}
			return response
		},

		userData() {
			if (this.userId === "new") {
				return null
			} else {
				let user = this.$store.state.users.find((val) => val.id == this.userId)
				return user
			}
		},
	},
}
</script>

<style lang="scss">
.registration {
	background-color: white;
	height: 90vh;
  width: 90vw;
  max-height: 500px;
  max-width: 800px;
	padding: 10px 0;
	.content {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		.fields,
		.picture {
			width: 50%;
			position: relative;
			padding-bottom: 40px;
			label {
				display: block;
				width: 90%;
				margin: 10px auto;
			}
			input,
			textarea,
			select {
				display: block;
				width: 90%;
				min-height: 30px;
				margin: 5px auto 10px auto;
				padding: 5px;
			}
			button {
				width: 90%;
				display: block;
				margin: 0 auto;
			}

			margin-bottom: 10px;
		}

		.picture {
			height: 350px;
			padding: 10px;
			.foto {
				width: 100%;
				height: 90%;
				border: 5px dotted rgba(200, 200, 200, 0.7);
				padding: 10px;
				img {
					height: 100%;
					margin: 0 auto;
					display: block;
				}
			}
		}
	}
	.confirmButton {
		display: block;
		margin: 0 auto;
		width: 45%;
		padding: 5px;
		font-size: 35px;
		font-weight: lighter;
	}
}
</style>
