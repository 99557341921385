import Vue from "vue"
import axios from "axios"
import store from "../store"
import router from "../router"

var api = axios.create({
	headers: {
		"Content-Type": "application/json",
	},
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 20000,
})

api.interceptors.request.use((config) => {
	config.headers.common["authorization"] = `Bearer ${localStorage.token}`
	return config
})

api.interceptors.request.use((config) => {
	store.state.loading = true
	return config
})

api.interceptors.response.use((response) => {
	store.state.loading = false
	return response
})

api.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		if (401 == error.response.status && router.currentRoute.path != "/login") {
			router.push("/login")
		} else {
			store.state.loading = false
			return Promise.reject(error)
		}
	}
)

Vue.use({
	install(Vue) {
		Vue.prototype.$api = api
	},
})

export default api
