<template>
	<transition name="fade">
		<div :is="screen" :userId="param" v-if="$store.state.users.length > 0" />
	</transition>
</template>

<script>
import List from "./components/List"
import Registration from "./components/Registration"

export default {
	components: {
		List,
		Registration,
	},

	props: ["param"],

	data() {
		return {}
	},

	created() {
		this.$usersApi.reloadUsers()
	},

	computed: {
		screen() {
			return this.param
				? this.$options.components.Registration
				: this.$options.components.List
		},
	},
}
</script>

<style lang="scss"></style>
