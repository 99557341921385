<template>
	<div id="app">
    <Loading />
    <transition name="fade">
			<Window
				v-if="$route.params.windowRoute"
				:window="$route.params.windowRoute"
				:param="$route.params.windowParam"
			/>
		</transition>
		<Home>
			<transition name="fade">
				<router-view />
			</transition>
		</Home>
	</div>
</template>

<script>
import Home from "./views/Home/Home"
import Loading from './components/Loading'
import Window from './components/Window'

export default {
	components: { Home, Loading, Window },
  mounted() {
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty("--vh", `${vh}px`)
		window.addEventListener("resize", this.changeHeight)
    if (!this.$route.path == "/") {
      this.$router.push("/")
    }
	},
	methods: {
		changeHeight() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty("--vh", `${vh}px`)
		},
	},
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: 0;
	overflow: hidden;
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar {
		width: 10px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #7e7e7e;
	}
}

*:not(input),
*:focus:not(input) {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline-style: none; /*IE*/
}

#app {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

#app,
input,
button {
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	font-size: 14px;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.6s ease;
	position: absolute;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.fade-move {
	transition: all 0.6s ease;
}
</style>
