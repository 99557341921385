import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import "./plugins/api"
import "./plugins/socket"
import "./plugins/formatDate"
import "./plugins/vex"
import "./classes/supportApi"
import "./classes/usersApi"
import "./classes/menusApi"
import "./classes/loadData"
import "./lang"

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
