import api from "../plugins/api"
import Store from "../store"
import Vue from "vue"
const $this = new Vue()

const menusApi = {
	reloadMenus: async () => {
    if (localStorage.token) {
      api.get(`/menus`).then((response) => {
        $this.$set(Store.state, "menuOptions", response.data)
      })
    }
	},
}

export default menusApi

Vue.use({
	install(Vue) {
		Vue.prototype.$menusApi = menusApi
	},
})
